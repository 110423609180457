customElements.define('c-menu', class extends HTMLElement {
    
    static observedAttributes = ["firstname", "lastname", "emailhash", "email", "user", "accounturl"];

	constructor() {
        super();
        this.attachShadow({ mode: 'open' });
    }

    connectedCallback() {

        const isSafari = window?.safari !== undefined || /^((?!chrome|android).)*safari/i.test(navigator?.userAgent);

        this.shadowRoot.innerHTML = `
        <span class="menu-area">
            <button class="m-btn">
                <span class="m-icon-names"></span>
                <img class="m-icon" onError="this.onerror=null;this.style.display='none'">
            </button>

            <span class="m-block">
                <a class="m-item-mail hide" id="email"></a>
                <div class="m-divider hide" id="divider-email"></div>
                <a class="m-item"       href="/"           id="link-subscription">My subscription</a>
                <a class="m-item"       href="/plans"      id="link-plans"       >Plans and pricing</a>
                <a class="m-item"       href="/profile"    id="link-profile"     >My Profile</a>
                <a class="m-item"       href="/team"       id="link-team"        >Team Members</a>
                <div class="m-divider"                     id="divider-links"></div>
                <a class="m-item"       href="/api/logout" id="link-logout"      >Sign Out</a>
            </span>

            <style>

            .m-item-mail {
                padding-top: 15px;
                display: block;
                padding-bottom: 15px;
                text-align: center;
                color: rgb(149, 146, 142);
                text-decoration: none;
                font-weight: bold;
                font-size: 12px;
                line-height:0;
            }

            .m-block {
                border: 1px solid #d5d3d0;
                background-color: rgb(255 255 255);
                border-radius: 8px;
                --tw-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
                box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
                padding-top: 5px;
                padding-bottom: 5px;
                transform-origin: top right;
                z-index: 10;
                right: 0px;
                position: absolute;
                display:none;
                width: 300px;
                top:36px;
            }

            .m-block:hover {
                display:block;
            }

            .menu-area {
                position: relative;
                display:block;
                height:36px;
            }

            .m-btn:focus + .m-block {
                display:block;
            }
            
            ${isSafari ? '/** Safari does not support .m-btn:focus **/\
            .m-btn:hover + .m-block { display:block; }\
            .menu-area:hover  .m-block { display:block; }' : ''}
 
            .m-item {
                padding-top: 10px;
                padding-bottom: 10px;
                padding-left: 30px;
                padding-right: 30px;
                display:block;
                text-decoration: none;
                color: rgb(55 65 81);
                line-height: 25px;
                cursor: pointer;
                font-size: 16px;
                font-weight: 500;
            }

            .m-item:hover {
                background-color:#80808014;
            }

            .m-btn {
                display:block;
                position:relative;
                margin: 0;
                padding: 0;
                cursor: pointer;
                background-image: none;
                text-transform: none;
                border: none;
                background-color: #a544c5;
                width: 36px;
                height: 36px;
                border-radius: 20px;
            }

            .m-btn:focus {
                box-shadow: 0 0 0 .1rem rgba(165,68,197,.2);
            }

            .m-icon {
                position:absolute;
                width: 36px;
                height: 36px;
                margin: auto;
                display: block;
                border-radius: 20px;
                border: 2px solid #a544c5;
                top: 50%;
                transform: translateY(-50%);
                right: 0;
                left: 0;
                box-sizing: border-box;
            }

            .m-icon-names {
                position: absolute;
                left: 0px;
                right: 0px;
                color: white;
                font-weight: 700;
                top: 50%;
                transform: translateY(-50%);
                font-size: 15px;
            }

            .m-icon-fallback {
                position:absolute;
                width: 36px;
                height: 36px;
                margin: auto;
                display: block;
            }

            .m-divider {
                border-top: .05rem solid #d5d3d0;
                height: .05rem;
                margin: .25rem 0;
            }

            .hide {
                display:none;
            }
            </style>
        </span>`;
    }
    
    disconnectedCallback() {
    // console.log("Custom element removed from page.");
    }

    attributeChangedCallback(name, oldValue, newValue) {
        if (oldValue !== newValue) {
            this[name] = newValue;
        }
    }

    get firstname () {
        return this?.getAttribute("firstname") ?? null;
    }

    set firstname (value) {
        this.setIconFromNames()
    }

    get lastname () {
        return this?.getAttribute("lastname") ?? null;
    }

    set lastname (value) {
        this.setIconFromNames()
    }

    set email (value) {
        if (value) {
            this.shadowRoot.querySelector('.m-item-mail').classList.remove('hide'); 
            this.shadowRoot.querySelector('.m-divider').classList.remove('hide'); 
            this.shadowRoot.querySelector('.m-item-mail').textContent = value;
            this.setIconFromNames()
        } else {
            this.shadowRoot.querySelector('.m-item-mail').classList.add('hide'); 
            this.shadowRoot.querySelector('.m-divider').classList.add('hide'); 
        }
    }

    get email () {
        return this?.getAttribute("email") ?? null;
    }

    get emailhash () {
        return this?.getAttribute("emailhash") ?? null;
    }

    set emailhash (value) {
        this.shadowRoot.querySelector('.m-icon').src = `https://gravatar.com/avatar/${value}?d=404`
    }

    get user () {
        return this?.getAttribute("user") ?? null;
    }

    set user (value) {   
        this.setUserUI();
    }

    get accounturl() {
        return this?.getAttribute("accounturl") ?? null;
    }

    set accounturl(value) {
        this.shadowRoot.querySelector('#link-subscription').href = value + '/';
        this.shadowRoot.querySelector('#link-plans').href = value + '/plans';
        this.shadowRoot.querySelector('#link-profile').href = value + '/profile';
        this.shadowRoot.querySelector('#link-team').href = value + '/team';
        this.shadowRoot.querySelector('#link-logout').href = value + '/api/logout';
    }

    setIconFromNames() {
        this.shadowRoot.querySelector('.m-icon-names').textContent = this?.firstname ? (this?.firstname?.[0]?.toUpperCase() ?? "") + (this?.lastname?.[0]?.toUpperCase() ?? "") : (this?.email?.[0]?.toUpperCase() ?? "") + (this?.email?.[1]?.toUpperCase() ?? "");
    }

    setUserUI (){
        if (this.user === "truep" && this.shadowRoot.querySelector('#link-subscription')) {
            this.shadowRoot.querySelector('#link-subscription').classList.add('hide');
            this.shadowRoot.querySelector('#link-plans').classList.add('hide');
            this.shadowRoot.querySelector('#link-profile').classList.add('hide');
            this.shadowRoot.querySelector('#link-team').classList.add('hide');
            this.shadowRoot.querySelector('#divider-links').classList.add('hide');
        } else if (this.user === "falsep" && this.shadowRoot.querySelector('#link-subscription')){
            this.shadowRoot.querySelector('#link-subscription').classList.remove('hide');
            this.shadowRoot.querySelector('#link-plans').classList.remove('hide');
            this.shadowRoot.querySelector('#link-profile').classList.remove('hide');
            this.shadowRoot.querySelector('#link-team').classList.remove('hide');
            this.shadowRoot.querySelector('#divider-links').classList.remove('hide');
        }
    }
});